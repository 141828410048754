import React, { useContext, useEffect, useState } from "react";
import styles from "./ReportsSpreadJs.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { I18nContext } from "../../i18n/I18nContext";
import {
  fetchTasksReport,
  fetchAssetsReport,
  fetchAttachmentsReport,
  fetchRecurrenceTasksReport,
  fetchTasksPrintReport,
} from "../../redux";
import Icon from "../Icon";

import GC from "@mescius/spread-sheets";
import { SpreadSheets, Worksheet } from "@mescius/spread-sheets-react";
import * as ExcelIO from "@mescius/spread-excelio";
import "@mescius/spread-sheets/styles/gc.spread.sheets.excel2016colorful.css";
import "@mescius/spread-sheets-shapes";
import Loader from "../Loader";

GC.Spread.Sheets.LicenseKey = ExcelIO.LicenseKey =
  "tapperaccept.normecgroup.com|tappertest.normecgroup.com,E376571434275533#B1QNdB5TpJXWlB7Mtd4M9MDNVFkR7RjR0VDbPVndPdWd8xkdkFES734TvlmNkhDVZd6SVFFZ4EXOHpmSmFkTKJWWmdlQvZWaX5EMoNEOnBTRzJTN7pkQUhGVktGNjJXdyMWe6cUaupFdr4mcxMnNvJDMB9WNPF5KBt6YTVXSD34KTNnb7lUOi5kb4gFNkxkQzolMihUZ4NUYxMGcUZWa6hHa7RnQnVFU9FEZONEOxUWcG5kYxl7bY3mTPdUQr56KrMUeK3EamR4ZC5kbSN5VXVDdB3mRlJjQ4oUSah7YvRlYzIkRUpmVUZ6LWJnWDtyTwgUOvcGeHdlVyE4VERzNXVzSNhjd7ZXU6o5awJjbnBHUiZ6aUxEVHBHSaVGbiojITJCLiUUQ5EUO7MUNiojIIJCL8QTO7YjMycjM0IicfJye35XX3JCSJpkQiojIDJCLigTMuYHITpEIkFWZyB7UiojIOJyebpjIkJHUiwiI6MjN4MDMgAzMxATNyAjMiojI4J7QiwiI6AzMwYjMwIjI0ICc8VkIsISbvNmLwV7byd6Yl5mcv9mL4NXZ4JXZwBXY4xSbvNmLwV7byd6Yl5mcv9mL4BXZjNWYyVGcwFGdiojIz5GRiwiIuYlLCByalVmYzxWYLByYl5mcv9kI0ISYONkIsUWdyRnOiwmdFJCLiMzM5UzNyQzM4EzN5YzNzIiOiQWSiwSfdJSZsJWYUR7b6lGUislOicGbmJCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPB3SOwBnNsN4VoZVeMp4N6onM7IzQsRWQxdTZB3UbIlVZ9gnW7VkdycjeIV4LKhkbWRGRO3Gb9dUbMd";

window.GC = GC;
const Reports = () => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.reports.loading);
  const allChosen = useSelector((state) => state.buildings.choosenBuildings);
  const [spread, setSpread] = useState({});

  const hostStyle = {
    width: "100%",
    height: "100%",
  };

  const workbookInit = (data) => {
    setSpread(data);
  };

  const reportsList = [
    {
      type: "tasks_open",
      name: t("open tasks report"),
      method: fetchTasksReport,
    },
    {
      type: "tasks_closed",
      name: t("closed tasks report"),
      method: fetchTasksReport,
    },
    {
      type: "assets",
      name: t("assets report"),
      method: fetchAssetsReport,
    },
    {
      type: "assets_changes",
      name: t("assets changes report"),
      method: fetchAssetsReport,
    },
    {
      type: "attachments",
      name: t("attachments report"),
      method: fetchAttachmentsReport,
    },
    {
      type: "recurrence_tasks",
      name: t("recurrence tasks report"),
      method: fetchRecurrenceTasksReport,
    },
    {
      type: "tasks_print",
      name: t("tasks print report"),
      method: fetchTasksPrintReport,
    },
  ];

  const downloadReport = async (type) => {
    const reportItem = reportsList.find((x) => x.type === type);
    const fileName = reportItem.name;

    const report = await dispatch(
      reportItem.method(
        t,
        type,
        allChosen.map((x) => x.id)
      )
    );
    const url = window.URL.createObjectURL(new Blob([report]));
    const link = document.createElement("a");
    link.href = url;
    link.download = `${fileName.replace(" ", "_")}.xlsx`;
    document.body.appendChild(link);
    link.click();
  };

  const openReport = async (type) => {
    const reportItem = reportsList.find((x) => x.type === type);
    const report = await dispatch(
      reportItem.method(
        t,
        type,
        allChosen.map((x) => x.id)
      )
    );
    const blob = new Blob([report]);

    let excelIO = new ExcelIO.IO();
    excelIO.open(
      blob,
      (json) => {
        spread.fromJSON(json);
      },
      (e) => {
        console.log(e);
      }
    );
  };

  return (
    <>
      <div className={styles.header_section}>
        <span className={styles.title}>{t("reports")}</span>
      </div>
      <div className={styles.reports_list}>
        {reportsList.map((report) => (
          <div className={styles.report_item} key={report.type}>
            <div className={styles.report_item_name}>{report.name}</div>
            <div className={styles.report_item_actions}>
              <div className={styles.action_button}>
                <Icon
                  name="eye-grey"
                  className={styles.action_button_icon}
                  onClick={() => openReport(report.type)}
                />
              </div>
              <div className={styles.action_button}>
                <Icon
                  name="download-grey"
                  className={styles.action_button_icon}
                  onClick={() => downloadReport(report.type)}
                />
              </div>
            </div>
          </div>
        ))}
      </div>

      {!!spread && (
        <div className={styles.report_preview}>
          <SpreadSheets
            // backColor={spreadBackColor}
            hostStyle={hostStyle}
            workbookInitialized={workbookInit}
          >
            <Worksheet></Worksheet>
          </SpreadSheets>
        </div>
      )}

      {!!loading && <Loader />}
    </>
  );
};
export default Reports;

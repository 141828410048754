import React, { useContext, useState } from "react";
import {
  FieldInput,
  FieldSelect,
  FieldLabel,
  FormControl,
  FieldError,
} from "../Input";
import styles from "./BooleanBuilder.module.scss";
import { I18nContext } from "../../i18n/I18nContext";

const BooleanBuilder = ({ property }) => {
  const { t } = useContext(I18nContext);

  const colorOptions = [
    { value: "#64b90c" },
    { value: "#d2042d" },
    { value: "#ee7203" },
    { value: "#0a49a7" },
    { value: "#707070" },
    { value: "#000000" },
  ];

  const [trueValue, setTrueValue] = useState(
    colorOptions.find((x) => x.value === property?.trueColor)?.value
  );
  const [falseValue, setFalseValue] = useState(
    colorOptions.find((x) => x.value === property?.falseColor)?.value
  );

  const customAssetOptionLabel = (option) => {
    return <div style={{ width: 200, height: 20, background: option.value }} />;
  };

  return (
    <>
      <div className={styles.row}>
        <FormControl>
          <FieldLabel>
            {t("true label")}
            <span className={styles.red}> *</span>
          </FieldLabel>
          <FieldInput size="m" name="trueLabel" />
          <FieldError name="trueLabel" />
        </FormControl>
        <div className={styles.indicator}> </div>
        <FormControl className={styles.selectField}>
          <FieldLabel>
            {t("true color")}
            <span className={styles.red}> *</span>
          </FieldLabel>
          <FieldSelect
            size="s"
            name="trueColor"
            options={colorOptions}
            singleValue={trueValue}
            customLabel={customAssetOptionLabel}
            onChange={(value) => setTrueValue(value)}
          />
          <FieldError name="trueColor" />
        </FormControl>
      </div>
      <div className={styles.row}>
        <FormControl>
          <FieldLabel>
            {t("false label")}
            <span className={styles.red}> *</span>
          </FieldLabel>
          <FieldInput size="m" name="falseLabel" />
          <FieldError name="falseLabel" />
        </FormControl>
        <div className={styles.indicator}> </div>
        <FormControl className={styles.selectField}>
          <FieldLabel>
            {t("false color")}
            <span className={styles.red}> *</span>
          </FieldLabel>
          <FieldSelect
            size="s"
            name="falseColor"
            options={colorOptions}
            singleValue={falseValue}
            customLabel={customAssetOptionLabel}
            onChange={(value) => setFalseValue(value)}
          />
          <FieldError name="falseColor" />
        </FormControl>
      </div>
    </>
  );
};

export default BooleanBuilder;
